















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Services extends Vue {
  @Prop(Boolean) readonly mobile!: boolean;
}
