import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import i18n from "@/i18n";
import Work from "@/views/Work.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: i18n.t("app.titles.home"),
    },
  },
  {
    path: "/our-work",
    name: "Work",
    component: Work,
    meta: {
      title: i18n.t("app.titles.work"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta) {
    const { title } = to.meta;
    document.title = `${
      typeof title === "function" ? title(to) : title
    } - ${i18n.t("app.name")}`;
  }
  next();
});

export default router;
