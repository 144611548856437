



















import { Component, Vue } from "vue-property-decorator";
import TopBar from "@/components/ui/TopBar.vue";
import AppFooter from "@/components/ui/AppFooter.vue";
import BlueSection from "@/components/work/BlueSection.vue";
import PinkSection from "@/components/work/PinkSection.vue";

export interface WorkItem {
  company: string;
  products: string[];
}

@Component({
  components: {
    PinkSection,
    BlueSection,
    AppFooter,
    TopBar,
  },
  metaInfo: {
    title: "Our work - Lockit QA",
  },
})
export default class Work extends Vue {
  workItems: WorkItem[] = [
    { company: "innersloth", products: ["amongUs"] },
    {
      company: "superHot",
      products: ["superHot", "superHotVr", "superHotMindcontrol"],
    },
    { company: "boneloaf", products: ["gangBeasts"] },
    { company: "poncle", products: ["vampireSurvivors"] },
    { company: "lazerbeam", products: ["teenageBlob"] },
    { company: "miscellaneumStudios", products: ["distractionMachine"] },
    { company: "frozenPixel", products: ["joeWander"] },
    { company: "playEveryWare", products: ["certification"] },
  ];

  get isOnMobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }
}
