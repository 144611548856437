












import { Component, Prop, Vue } from "vue-property-decorator";
import TestimonyCard from "@/components/home/TestimonyCard.vue";
import CarouselControl from "@/components/ui/CarouselControl.vue";

@Component({ components: { CarouselControl, TestimonyCard } })
export default class CardCarousel extends Vue {
  @Prop(Boolean) readonly mobile!: boolean;
  @Prop(Number) readonly length!: number;

  currentItem = 0;

  decrementSlide(): void {
    this.currentItem =
      this.currentItem === 0 ? this.length - 1 : this.currentItem - 1;
  }

  incrementSlide(): void {
    this.currentItem =
      this.currentItem === this.length - 1 ? 0 : this.currentItem + 1;
  }

  updateCurrentItem(value: number): void {
    this.currentItem = value;
  }
}
