import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import "@fortawesome/fontawesome-free/css/all.css";

import AOS from "aos";
import "aos/dist/aos.css";

Vue.config.productionTip = false;

Vue.use(VueMeta);

new Vue({
  router,
  i18n,
  vuetify,
  mounted() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount("#app");
