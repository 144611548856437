
























import { Component, Prop, Vue } from "vue-property-decorator";
import Company from "@/components/work/Company.vue";
import CardCarousel from "@/components/ui/CardCarousel.vue";
import WorkCard from "@/components/work/WorkCard.vue";
import CarouselControl from "@/components/ui/CarouselControl.vue";
import { WorkItem } from "@/views/Work.vue";

@Component({
  components: { CarouselControl, WorkCard, CardCarousel, Company },
})
export default class PinkSection extends Vue {
  @Prop(Boolean) readonly mobile!: boolean;
  @Prop({ type: Object as () => WorkItem }) readonly workItem!: WorkItem;

  currentItem = 0;

  updateCurrentItem(value: number): void {
    this.currentItem = value;
  }
}
