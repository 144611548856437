


































import { Component, Prop, Vue } from "vue-property-decorator";
import { environment } from "@/environment";

@Component
export default class AppFooter extends Vue {
  @Prop(Boolean) readonly mobile!: boolean;

  googleFormUrl = environment.googleFormUrl;
}
