







import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component
export default class CarouselControl extends Vue {
  @Prop(Number) readonly length!: boolean;
  @PropSync("currentItem", Number) currentItemSync!: number;
}
