







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WorkCard extends Vue {
  @Prop(String) readonly localeId!: string;
}
