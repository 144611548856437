


















import { Component, Prop, Vue } from "vue-property-decorator";
import TestimonyCard from "@/components/home/TestimonyCard.vue";
import CardCarousel from "@/components/ui/CardCarousel.vue";

@Component({
  components: { CardCarousel, TestimonyCard },
})
export default class Testimonies extends Vue {
  @Prop(Boolean) readonly mobile!: boolean;

  testimonies = [
    "innersloth",
    "robotTeddy",
    "boneloaf",
    "playEveryWare",
    "superHot",
    "teenageBlob",
  ];
}
