import { render, staticRenderFns } from "./TestimonyCard.vue?vue&type=template&id=c69d06ca&scoped=true&lang=pug&"
import script from "./TestimonyCard.vue?vue&type=script&lang=ts&"
export * from "./TestimonyCard.vue?vue&type=script&lang=ts&"
import style0 from "./TestimonyCard.vue?vue&type=style&index=0&id=c69d06ca&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c69d06ca",
  null
  
)

export default component.exports