



















import { Component, Prop, Vue } from "vue-property-decorator";
import { environment } from "@/environment";

@Component
export default class TopBar extends Vue {
  @Prop(Boolean) readonly mobile!: boolean;

  maxHeight = 200;
  minHeight = 120;
  height = this.mobile ? 100 : this.maxHeight;

  googleFormUrl = environment.googleFormUrl;

  created(): void {
    window.addEventListener("scroll", this.handleScroll);
  }

  mounted(): void {
    this.handleScroll();
  }

  destroyed(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(): void {
    if (this.mobile) {
      this.height = 100;
      return;
    }
    if (window.scrollY < 200) {
      this.height = this.maxHeight;
    } else if (window.scrollY > 600) {
      this.height = this.minHeight;
    } else {
      this.height =
        this.maxHeight - (this.minHeight * (window.scrollY - 200)) / 600;
    }
  }
}
