












import { Component, Vue } from "vue-property-decorator";
import TopBar from "@/components/ui/TopBar.vue";
import Hero from "@/components/home/Hero.vue";
import Services from "@/components/home/Services.vue";
import AppFooter from "@/components/ui/AppFooter.vue";
import Testimonies from "@/components/home/Testimonies.vue";

@Component({
  components: {
    Testimonies,
    AppFooter,
    Services,
    Hero,
    TopBar,
  },
  metaInfo: {
    title: "Home - Lockit QA",
  },
})
export default class Home extends Vue {
  get isOnMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }
}
